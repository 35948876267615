function FeaturedInfo() { 
  return ( 
    <div className="featured responsive"> 
      <div className="featuredItem"> 
        <span className="featuredTitle">Week 12</span> 
        <div className="featuredMoneyContainer"> 
          <span className="featuredMoney">4 - 1</span> 
          {/* <span className="featuredMoneyRate"> 
            -11.4
          </span>  */}
        </div> 
        {/* <span className="featuredSub"> Compared to last month</span>  */}
      </div> 
        <div className="featuredItem"> 
            <span className="featuredTitle">Season</span> 
            <div className="featuredMoneyContainer"> 
            <span className="featuredMoney">23 - 17</span> 
            {/* <span className="featuredMoneyRate"> 
                +2.4 
            </span>  */}
            </div> 
            {/* <span className="featuredSub"> Compared to last month</span>  */}
        </div> 
        </div> 
    ); 
} 
export default FeaturedInfo;
