import React from 'react';
import FeaturedInfo from '../components/FeaturedInfo';
import FeaturedInfoRules from '../components/FeaturedInfoRules';

const players13 = [
  { id: 1, week: '13', game: 'Dolphins @ Packers', bet: 'Packers ML -175', result: 'TBD' },
  { id: 2, week: '13', game: 'Rams @ Saints', bet: 'Rams ML -155', result: 'TBD' },
  { id: 3, week: '13', game: 'Eagles @ Ravens', bet: 'Over 51', result: 'TBD' }
];

const players12 = [
  { id: 1, week: '12', game: 'Bucs @ Giants', bet: 'Bucs ML -290', result: 'Winner' },
  { id: 2, week: '12', game: 'Patriots @ Dolphins', bet: 'Under 46', result: 'Loss' },
  { id: 3, week: '12', game: 'Vikings @ Bears', bet: 'Vikings ML -175', result: 'Winner' },
  { id: 4, week: '12', game: '49ers @ Packers', bet: 'Packers ML -270', result: 'Winner' },
  { id: 5, week: '12', game: 'Broncos @ Reaiders', bet: 'Over 41.5', result: 'Winner' }
];

const players11 = [
  { id: 1, week: '11', game: 'Bengals @ Chargers', bet: 'Under 48', result: 'Loss' },
  { id: 2, week: '11', game: 'Rams @ Patriots', bet: 'Rams ML -205', result: 'Win' },
  { id: 3, week: '11', game: 'Ravens @ Steelers', bet: 'Ravens ML -185', result: 'Loss' },
];

const players10 = [
  { id: 1, week: '10', game: 'Dolphins @ Rams', bet: 'Rams -2', result: 'Loss' },
];

const players9 = [
  { id: 1, week: '9', game: 'Cowboys @ Falcons', bet: 'Falcons -3.5', result: 'Win' },
  { id: 2, week: '9', game: 'Chargers @ Browns', bet: 'Chargers -1 && Under 42.5', result: 'Win' },
  { id: 3, week: '9', game: 'Rams @ Seahawks', bet: 'Rams -1', result: 'Win' },
];

const players8 = [
  { id: 1, week: '8', game: 'Vikings @ Rams', bet: '1H Vikings -1', result: 'Loss' },
  { id: 2, week: '8', game: 'Vikings @ Rams', bet: '2H Vikings -1', result: 'Loss' },
  { id: 3, week: '8', game: 'Cardinals @ Dolphins', bet: 'Cardinals +4.5', result: 'Win' },
  { id: 4, week: '8', game: 'Packers @ Jaguars', bet: 'Packers -3.5', result: 'Loss' },
  { id: 5, week: '8', game: 'Falcons @ Bucs', bet: 'Falcons -2.5', result: 'Win' },
  { id: 6, week: '8', game: 'Saints @ Chargers', bet: 'Saints +7', result: 'Loss' },
];

const players7 = [
  { id: 1, week: '7', game: 'Chiefs @ 49ers', bet: 'Chiefs +2', result: 'Win' },
  { id: 2, week: '7', game: 'Chargers @ Cardinals', bet: 'Chargers -1.5 && Under 44', result: 'Loss' },
  { id: 3, week: '7', game: 'Dolphins @ Colts', bet: 'Colts -3', result: 'Win' },
];

const players6 = [
  { id: 1, week: '6', game: 'Jaguars @ Bears', bet: 'Bears -1', result: 'Win' },
  { id: 2, week: '6', game: 'Lions @ Cowboys', bet: 'Lions -3.5', result: 'Win' },
];

const players5 = [
  { id: 1, week: '5', game: 'Ravens @ Bengals', bet: 'Ravens -2.5', result: 'Win' },
  { id: 2, week: '5', game: 'Bills @ Texans', bet: 'Bills +1', result: 'Loss' },
  { id: 3, week: '5', game: 'Packers @ Rams', bet: 'Packers -3', result: 'Win' },
  { id: 4, week: '5', game: 'Saints @ Chiefs', bet: 'Over 43', result: 'Loss' },
];

const players4 = [
  { id: 1, week: '4', game: 'Browns @ Raiders', bet: 'Browns -2.5 && Over 36.6', result: 'Loss' },
  { id: 2, week: '4', game: 'Saints @ Falcons', bet: 'Over 41.5', result: 'Win' },
  { id: 3, week: '4', game: 'Cardinals @ Commanders', bet: 'Cardinals -3.5', result: 'Loss' },
  { id: 4, week: '4', game: 'Seahawks @ Lions', bet: 'Lions -3.5', result: 'Win' },
];

const players3 = [
  { id: 1, week: '3', game: 'Texans @ Vikings', bet: 'Vikings ML', result: 'Win' },
  { id: 2, week: '3', game: 'Bears @ Colts', bet: 'Under 43.5', result: 'Win' },
];

const players2 = [
  { id: 1, week: '2', game: 'Saints @ Cowboys', bet: 'Over 46.5', result: 'Win' },
  { id: 2, week: '2', game: 'Vikings @ 49ers', bet: 'Over 45.5', result: 'Loss' },
  { id: 3, week: '2', game: 'Steelers @ Broncos', bet: 'Steelers -2.5', result: 'Win' },
];

const players = [
  { id: 1, week: '1', game: 'Ravens @ Chiefs', bet: 'Under 46.5', result: 'Loss' },
  { id: 2, week: '1', game: 'Jaguras @ Dolphins', bet: 'Dolphins -3.5', result: 'Loss' },
  { id: 3, week: '1', game: 'Raiders @ Chargers', bet: 'Over 40', result: 'Loss' },
  { id: 4, week: '1', game: 'Cowboys @ Browns', bet: 'Cowboys -2 && Over 41', result: 'Win' },
];

const NFLStatsTable = () => (
  <>
  <div className="item3">
    <FeaturedInfoRules />
  </div>
  <br /><br />

  <div className="item3">
    <FeaturedInfo />
  </div>
  <br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players13.map(player13 => (
          <tr key={player13.id}>
            <td>{player13.week}</td>
            <td>{player13.game}</td>
            <td>{player13.bet}</td>
            <td className="td-result">{player13.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players12.map(player12 => (
          <tr key={player12.id}>
            <td>{player12.week}</td>
            <td>{player12.game}</td>
            <td>{player12.bet}</td>
            <td className="td-result">{player12.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players11.map(player11 => (
          <tr key={player11.id}>
            <td>{player11.week}</td>
            <td>{player11.game}</td>
            <td>{player11.bet}</td>
            <td className="td-result">{player11.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players10.map(player10 => (
          <tr key={player10.id}>
            <td>{player10.week}</td>
            <td>{player10.game}</td>
            <td>{player10.bet}</td>
            <td className="td-result">{player10.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players9.map(player9 => (
          <tr key={player9.id}>
            <td>{player9.week}</td>
            <td>{player9.game}</td>
            <td>{player9.bet}</td>
            <td className="td-result">{player9.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players8.map(player8 => (
          <tr key={player8.id}>
            <td>{player8.week}</td>
            <td>{player8.game}</td>
            <td>{player8.bet}</td>
            <td className="td-result">{player8.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players7.map(player7 => (
          <tr key={player7.id}>
            <td>{player7.week}</td>
            <td>{player7.game}</td>
            <td>{player7.bet}</td>
            <td className="td-result">{player7.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players6.map(player6 => (
          <tr key={player6.id}>
            <td>{player6.week}</td>
            <td>{player6.game}</td>
            <td>{player6.bet}</td>
            <td className="td-result">{player6.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players5.map(player5 => (
          <tr key={player5.id}>
            <td>{player5.week}</td>
            <td>{player5.game}</td>
            <td>{player5.bet}</td>
            <td className="td-result">{player5.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players4.map(player4 => (
          <tr key={player4.id}>
            <td>{player4.week}</td>
            <td>{player4.game}</td>
            <td>{player4.bet}</td>
            <td className="td-result">{player4.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players3.map(player3 => (
          <tr key={player3.id}>
            <td>{player3.week}</td>
            <td>{player3.game}</td>
            <td>{player3.bet}</td>
            <td className="td-result">{player3.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players2.map(player2 => (
          <tr key={player2.id}>
            <td>{player2.week}</td>
            <td>{player2.game}</td>
            <td>{player2.bet}</td>
            <td className="td-result">{player2.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />
  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players.map(player => (
          <tr key={player.id}>
            <td>{player.week}</td>
            <td>{player.game}</td>
            <td>{player.bet}</td>
            <td className="td-result">{player.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
  </div>
  </>
);

export default NFLStatsTable;
